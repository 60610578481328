import React from 'react';
import { Router } from '../routes';
import Layout from '../components/Layout';
import { withI18next } from '../lib/withI18n';

class Error extends React.Component {
    static async getInitialProps(ctx) {
        const statusCode = ctx.res ? ctx.res.statusCode : ctx.err ? ctx.err.statusCode : null;

        return {
            statusCode
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            ...props,
        };
    }

    render() {
        const { oCmsHeader, oCmsFooter, routerLang, t } = this.props;
        return (
            <Layout header={oCmsHeader.content} footer={oCmsFooter.content}>
                <div className="intro-alt">
                    <div className="decoration-arrow color-white">
                        <div className="decoration__img bg-purple" />{/* <!-- /.decoration__img --> */}
                    </div>{/* <!-- /.decoration-arrow --> */}
                </div>{/* <!-- /.intro-alt --> */}
                <div style={{ minHeight: '500px', position: 'relative', textAlign: 'center' }}>
                    <div style={{
                        position : 'absolute', top : '50%', left : '50%', transform : 'translate(-50%, -50%)',
                    }}
                    >
                        <p style={{ fontSize: '30pt' }}>404 {t('notFound')}</p>
                        <button className="btn btn--color color-gray" type="button" onClick={ () => Router.pushRoute('home', { lang: routerLang })}>
                            <span className="btn__content">{t('returnToHomepage')}</span>
                        </button>
                    </div>
                </div>
            </Layout>
        );
    }
}

Error.defaultProps = {
    contentHtml : {
        content : '',
    },
    oCmsHeader : {
        content : '',
    },
    oCmsFooter : {
        content : '',
    },
    message : '404 Not found',
};

export default withI18next(['common'])(Error);
